<template>
  <v-row>
    <v-col cols="3">
      <v-card class="secondary" dark>
        <v-card-title>
          <v-icon dark size="50">mdi-keyboard-return</v-icon>
          Returns
        </v-card-title>
        <v-card-text>
          <div class="d-flex align-center justify-space-around">
            <div class="d-flex flex-column">
              <h3 class="font-weight-bold">Done : 0</h3>
            </div>
          </div>
        </v-card-text>
      </v-card>
    </v-col>
    <v-col cols="3">
      <v-card>
        <v-card-title>
          <v-icon size="50">mdi-sail-boat</v-icon>
          Vacations
        </v-card-title>
        <v-card-text>
          <div class="d-flex align-center justify-space-around">
            <!-- <div class="d-flex flex-column"> -->
            <h3 class="font-weight-bold">Done : 0</h3>
            <h3 class="font-weight-bold">Accepted : 0</h3>
          </div>
          <!-- </div> -->
        </v-card-text>
      </v-card>
    </v-col>
    <v-col cols="3">
      <v-card>
        <v-card-title>
          <v-icon size="50">mdi-checkbox-marked</v-icon>
          Tasks
        </v-card-title>
        <v-card-text>
          <div class="d-flex align-center justify-space-around">
            <!-- <div class="d-flex flex-column"> -->
            <h3 class="font-weight-bold">Done : 0</h3>
            <h3 class="font-weight-bold">All : 0</h3>
          </div>
        </v-card-text>
      </v-card>
    </v-col>
    <v-col cols="3">
      <v-card>
        <v-card-title>
          <v-icon size="50">mdi-bullseye-arrow</v-icon>
          Targets
        </v-card-title>
        <v-card-text>
          <div class="d-flex align-center justify-space-around">
            <!-- <div class="d-flex flex-column"> -->
            <h3 class="font-weight-bold">Done : 0</h3>
            <h3 class="font-weight-bold">All : 0</h3>
          </div>
        </v-card-text>
      </v-card>
    </v-col>
    <v-col cols="8">
      <v-card>
        <v-card-title>
          Orders
          <v-tabs v-model="orders">
            <v-tab value="PharmacyOrders">
              Pharmacy Orders
            </v-tab>
            <v-tab value="RepositoryOrders">
              Repository Orders
            </v-tab>
            <v-tab value="DoctorOrders">
              Doctor Orders
            </v-tab>
          </v-tabs>
        </v-card-title>
        <v-card-text>
          <orders :typeFrom="type"></orders>
        </v-card-text>
      </v-card>
    </v-col>
    <v-col cols="4">
      <v-card>
        <v-card-title>
          Zones
        </v-card-title>
        <v-card-text>
          <GmapMap
            ref="mapRef"
            :center="{ lat: 33.312805, lng: 44.361488 }"
            :zoom="6"
            @click="mark"
            map-type-id="terrain"
            style="width: 100%; height: 50vh"
          >
            <GmapMarker
              v-for="zone in zones"
              :key="zone.id"
              :position="google && new google.maps.LatLng(zone.lat, zone.log)"
            />
          </GmapMap>
        </v-card-text>
      </v-card>
    </v-col>
    <v-col cols="6">
      <v-card>
        <v-card-title>
          Reports
          <v-tabs v-model="reports">
            <v-tab value="PharmacyOrders">
              Pharmacy Reports
            </v-tab>
            <v-tab value="RepositoryOrders">
              Repository Reports
            </v-tab>
            <v-tab value="DoctorOrders">
              Doctor Reports
            </v-tab>
          </v-tabs>
        </v-card-title>
        <v-card-text>
          <pharmacy-reports
            v-if="reports == 0"
            :child="true"
          ></pharmacy-reports>
          <repository-reports
            v-if="reports == 1"
            :child="true"
          ></repository-reports>
          <doctor-reports v-if="reports == 2" :child="true"></doctor-reports>
        </v-card-text>
      </v-card>
    </v-col>
    <v-col cols="6">
      <v-card>
        <v-card-title>
          FMS
        </v-card-title>
        <v-card-text>
          <FMS :child="true"></FMS>
        </v-card-text>
      </v-card>
    </v-col>
  </v-row>
</template>
<script>
import { gmapApi } from "vue2-google-maps";
import Orders from "../accounts/orders/Orders.vue";
import DoctorReports from "../archive/DoctorReports/DoctorReports.vue";
import PharmacyReports from "../archive/PharmacyReports/PharmacyReports.vue";
import RepositoryReports from "../archive/RepositoryReports/RepositoryReports.vue";
import FMS from "../management/fms/FMS.vue";
export default {
  computed: {
    google: gmapApi,
    type() {
      let orders = ["PharmacyOrders", "RepositoryOrders", "DoctorOrders"];
      return orders[this.orders];
    },
  },
  components: {
    Orders,
    PharmacyReports,
    RepositoryReports,
    DoctorReports,
    FMS,
  },
  data() {
    return {
      orders: 0,
      reports: 0,
      zones: [],
    };
  },
  created() {
    this.getZones();
  },
  methods: {
    getZones() {
      this.$http.get("/zones").then((response) => {
        this.zones = response.data.data;
      });
    },
  },
  watch: {
    orders() {
      this.$emit("updateOrders");
    },
  },
};
</script>
