<template>
  <v-dialog persistent v-model="dialog" width="400">
    <v-card :loading="loading" outlined>
      <v-card-title class="text-h5 my-3">
        {{ $t("accept-fms") }}
        <v-spacer></v-spacer>
        <v-btn @click="dialog = !dialog" text color="error">
          <f-icon icon="times-circle" />
        </v-btn>
      </v-card-title>
      <v-card-text>
        <v-form :loading="loading">
          <v-row>
            <v-col cols="12">
              <v-textarea outlined :label="$t('note')"></v-textarea>
            </v-col>
          </v-row>
          <div class="d-flex justify-end">
            <v-btn @click="submit" color="success" block>
              <v-icon>mdi-check</v-icon>
              {{ $t("accept") }}</v-btn
            >
          </div>
        </v-form>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>
<script>
export default {
  props: ["dialog"],
  data() {
    return {
      loading: false,
      fms: {},
      inputs: {
        note: "",
        status: 0,
      },
    };
  },
  methods: {
    submit() {
      this.loading = true;
      switch (this.$store.state.userInfo.userData.roles[0]) {
        case "Supervisor":
          this.inputs.status = 3;
          break;
        case "Teamleader":
          this.inputs.status = 1;
          break;
        case "Manager":
          this.inputs.status = 2;
          break;
        default:
          this.inputs.status = 7;
          break;
      }
      this.$http
        .put("/FMS/changeStatus/" + this.fms.id, this.inputs)
        .then(() => {});
    },
  },
  watch: {
    dialog() {
      this.loading = false;
      this.status = 0;
      this.fms = this.$store.state.fms;
      this.$emit("updated", this.dialog);
    },
  },
};
</script>
