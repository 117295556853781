<template>
<div>
    <v-dialog v-model="$store.state.dialog" width="1200" :fullscreen="isfullscreen" persistent transition="dialog-bottom-transition">
        <v-card flat>
            <v-toolbar outlined flat color="grey lighten-4">
                <v-toolbar-title>FMS</v-toolbar-title>
                <v-spacer />
                <v-btn icon @click="isfullscreen = !isfullscreen">
                    <v-icon small> {{ isfullscreen ? "mdi-arrow-collapse" : "mdi-arrow-expand" }} </v-icon>
                </v-btn>
                <v-btn @click="close()" icon>
                    <v-icon>mdi-close</v-icon>
                </v-btn>
            </v-toolbar>

            <v-form @submit.prevent="submit()" ref="form" >
                <v-container>
                    <h3>{{$store.state.itemDetails.representativeFullName}}</h3>
                    <v-card outlined>
                        <v-container>
                            <!-- <pre>
                            {{item}}
                            </pre> -->
                            
                            <v-row v-for="(p,index) in item.products" :key="index">
                                <v-col>
                                    <v-alert text>
                                        <v-row>
                                            <v-col>
                                                <v-row align="center" class="mx-2">
                                                    <h3 class="mr-2">{{p.product.tradeName}} ( {{p.qty}} )</h3>
                                                    <v-switch v-model="p.product.switch" :indeterminate="Batchesloading" @click="getBatches(p.product.id, index,p.qty)" />
                                                </v-row>
                                            </v-col>
                                            <v-col v-if="p.currentBatches">
                                                <v-autocomplete auto-select-first clearable v-model="p.batchId" item-text="batchNo" item-value="id" :items="p.currentBatches.data.data" class="mr-1" dense="" label="BatchNo" :loading="Batchesloading" no-data-text="لا توجد بيانات" hide-details="" outlined="" filled="">
                                                    <template v-slot:selection="data">
                                                        <v-chip v-bind="data.attrs" :input-value="data.selected" close color="grey" @click="data.select" @click:close="remove(data.item)">
                                                            No. {{ data.item.batchNo }}
                                                            <small class="mx-1">
                                                                {{
                                                              $service.formatDate(
                                                                new Date(data.item.expire),
                                                                false
                                                              )
                                                            }}</small>
                                                        </v-chip>
                                                    </template>
                                                    <template v-slot:item="data">
                                                        <template>
                                                            <v-list-item-content>
                                                                <v-list-item-title>
                                                                    No. {{ data.item.batchNo }} </v-list-item-title>
                                                                <v-list-item-subtitle>
                                                                    {{
                                                                $service.formatDate(
                                                                  new Date(data.item.expire),
                                                                  false
                                                                )
                                                              }}
                                                                </v-list-item-subtitle>

                                                                <v-list-item-subtitle>
                                                                    Available QYT : {{ data.item.totalNumberOfUnit}}
                                                                </v-list-item-subtitle>
                                                            </v-list-item-content>
                                                        </template>
                                                    </template>
                                                </v-autocomplete>
                                            </v-col>
                                        </v-row>
                                    </v-alert>
                                </v-col>
                              
                            </v-row>
                        </v-container>
                    </v-card>
                    <v-col cols="12" md="12">
                        <v-row class="mt-2">
                            <v-btn type="submit" :loading="loading" x-large color="primary" class="btn">Save</v-btn>
                            <v-spacer />
                            <v-btn @click="close()" x-large color="error" class="mr-2 btn" outlined>Cancel</v-btn>
                        </v-row>
                    </v-col>
                </v-container>
            </v-form>

        </v-card>
    </v-dialog>
</div>
</template>

<script>
export default {

    data() {
        return {
            isfullscreen: false,
            loading: false,
            Batchesloading: false,
            item: {},

        }
    },

    mounted() {
        this.reset()

        this.$eventBus.$on('fill-fields', () => {
            console.log(this.$store.state.itemDetails)
            this.reset()
            this.item = this.$store.state.itemDetails

        })
    },

    methods: {

        reset() {
            this.item = {}
        },

        async getBatches(productId, index, qty) {
            this.Batchesloading = true
            try {
                this.item.products[index].currentBatches = await this.$http.get(`ProductBatches?ProductId=${productId}`)
                console.log('this.item.products[index].currentBatches.data')
                console.log(this.item.products[index].currentBatches.data.data)
                
                for (let i = 0; i < this.item.products[index].currentBatches.data.data.length; i++) {
                    console.log('index '+ i)
                    if(this.item.products[index].currentBatches.data.data[i].totalNumberOfUnit >= qty){
                        console.log('in')
                        this.item.products[index].batchId = this.item.products[index].currentBatches.data.data[i].id
                        console.log('this.item.products[index].batchId')
                        console.log(this.item.products[index].product.batchId)
                        break;
                    }
                }
            } catch (error) {
                console.log(error)
            } finally {
                this.Batchesloading = false
            }
        },

        submit() {
            this.addItem()
        },

        addItem() {
            this.loading = true
            var data = []
            for (let i = 0; i < this.item.products.length; i++) {
                data.push({
                    fmsProductId: this.item.products[i].id,
                    productBatchId: this.item.products[i].batchId
                })                
            }
            this.$http.post(`FMS/fmsDone/${this.$store.state.itemDetails.id}`, data).then(res => {
                this.close()
                this.$eventBus.$emit(`refresh`)
                // this.$store.commit("UPDATE_SNACKBAR", true);
                console.log(res)
            }).catch(err => {
                // this.$service.faild("error")
                console.log(err)
                this.$store.commit("UPDATE_SNACKBAR", true);
            }).finally(() => this.loading = false)
        },

       

        close() {
            
            this.$store.commit('setDialog')
            this.reset()
            this.$store.commit('setItemDetails', {})
            this.resetValidation()
            
        },

        resetValidation() {
            this.$refs.form.resetValidation()
        },

    },
}
</script>
