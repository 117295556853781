<template>
  <div>
    <accept
      :dialog="accept"
      @updated="
        (_) => {
          accept = _;
          getFMS();
        }
      "
    />
    <!-- <done
      :dialog="doneDialog"
      @updated="
        (_) => {
          doneDialog = _;
          getFMS();
        }
      "
    /> -->
    <Dialog/>
    <div
      v-if="!child"
      class="d-flex align-content-center justify-space-between"
    >
      <h1 class="mb-3">{{ $t("free-medical-sample") }}</h1>
    </div>
    <v-card v-if="!child" :loading="loading" class="transparent">
      <v-card-text>
        <v-row>
          <v-col cols="3" style="padding-bottom: 0">
            <label for="se">{{ $t("search") }}</label>
            <v-text-field
              hide-detials="auto"
              class="mt-2"
              :label="$t('search')"
              id="se"
              solo
              filled
              :loading="loading"
              clearable
              v-model="options.Search"
              append-icon="mdi-magnify"
              type="search"
            ></v-text-field>
          </v-col>
          <!-- v-chip-group statues -->
          <v-col cols="12" style="padding-top: 0">
            <v-chip-group
              v-model="status"
              @change="getFMS()"
              :loading="loading"
              active-class="success"
            >
              <v-chip
                large
                v-for="(item, index) in statues"
                :key="index"
                filter
                :value="item.key"
              >
                {{ item.name }}
              </v-chip>
            </v-chip-group>
          </v-col>
          <v-col cols="12">
            <v-data-table
              disable-pagination
              :headers="headers"
              :items="fms"
              :no-data-text="$t('no-data')"
              hide-default-footer
              :server-items-length="total"
            >
              <template v-slot:item.created="{ item }">
                <span dir="ltr">
                  {{ $service.formatDate(new Date(item.created), false) }}
                </span>
              </template>
              <template v-slot:item.status="{ item }">
                <v-chip label color="grey">{{
                  statues.find((st) => st.key == item.status).name
                }}</v-chip>
              </template>
              <template v-slot:item.products="{ item }">
                <v-chip v-for="product in item.products" :key="product.id">
                  <span class="font-weight-bold mx-1 ">
                    {{ product.product.tradeName }}
                  </span>
                  {{ product.qty }}</v-chip
                >
              </template>
              <template v-slot:item.actions="{ item }">
                <div v-if="item.status != 9" class="d-flex">
                  <!-- <v-btn @click="acceptFMS(item)" color="success" class="mx-1">
                    <v-icon>mdi-check</v-icon>
                    {{ $t("accept") }}
                  </v-btn> -->
                  <v-btn @click="openEditDialog(item)" color="warning">
                    <v-icon>mdi-package</v-icon>
                    {{ $t("done") }}
                  </v-btn>
                </div>
              </template>
            </v-data-table>
            <!-- <div class="d-flex justify-center">
              <v-pagination
                v-model="options.PageIndex"
                :length="parseInt(total / options.PageSize) + 1"
              />
            </div> -->
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
    <v-data-table
      disable-pagination
      v-else
      :headers="headers"
      :items="fms"
      :no-data-text="$t('no-data')"
      hide-default-footer
      :server-items-length="total"
    >
      <template v-slot:item.created="{ item }">
        <span dir="ltr">
          {{ $service.formatDate(new Date(item.created), false) }}
        </span>
      </template>
      <template v-slot:item.status="{ item }">
        <v-chip label color="grey">{{
          statues.find((st) => st.key == item.status).name
        }}</v-chip>
      </template>
      <template v-slot:item.products="{ item }">
        <v-chip v-for="product in item.products" :key="product.id">
          <span class="font-weight-bold mx-1 ">
            {{ product.product.tradeName }}
          </span>
          {{ product.qty }}</v-chip
        >
      </template>
      <template v-slot:item.actions="{ item }">
        <div v-if="item.status != 9" class="d-flex">
          <!-- <v-btn @click="acceptFMS(item)" color="success" class="mx-1">
            <v-icon>mdi-check</v-icon>
            {{ $t("accept") }}
          </v-btn> -->
          <!-- <v-btn @click="openEditDialog(item)" color="warning">
            <v-icon>mdi-package</v-icon>
            {{ $t("done") }}
          </v-btn> -->
        </div>
      </template>
    </v-data-table>
    <div class="d-flex justify-center">
      <v-pagination
        v-model="options.PageIndex"
        :length="parseInt(total / options.PageSize) + 1"
        @input="getFMS()"
      />
    </div>
  </div>
</template>
<script>
import Accept from "./Accept.vue";
import Dialog from "./dialog.vue";
export default {
  props: ["child"],
  name: "FMS",
  components: {
    Accept,
    Dialog,
  },
  data() {
    return {
      loading: false,
      accept: false,
      statues: [],
      total: 0,
      fms: [],
      doneDialog: false,
      doneFMS: {},
      status: 0,
      options: {
        Search: "",
        Status: "",
        PageSize: 500,
        PageIndex: 1,
        OfficeId: this.$store.state.userInfo.office.id,
        CompanyId: this.$store.state.company.id,
      },
      headers: [
        {
          text: this.$t('representative'),
          align: "start",
          sortable: false,
          value: "representativeFullName",
        },
        { text: this.$t("status"), value: "status" },
        { text: this.$t("products"), value: "products" },
        { text: this.$t("products-count"), value: "productsCount" },
        { text: this.$t("note"), value: "note" },
        { text: this.$t("created"), value: "created" },
        { text: this.$t("actions"), value: "actions" },
      ],
    };
  },
  mounted() {

        this.getItems()

        this.$eventBus.$on(`refresh`, () => {
            this.getItems()
        })

    },

    beforeDestroy() {
        this.$eventBus.$off(`refresh`)
    },
  methods: {

    getItems(){
      this.$http.get("/fms/statuses").then((st) => {
      this.statues = st.data.result;
      this.statues.unshift({ key: this.statues.length + 1, name: "All" });
      this.status = this.statues.length;
      this.loading = false;
      if (this.role >= 1) {
        this.options.CompanyId = this.$store.state.userInfo.userData.companyId;
      }
      this.getFMS();
    });
    },
    
    openEditDialog(item) {
      item.dialogType = 'edit'
      this.$store.commit('setItemDetails', item)
      this.$store.commit('setDialog')
      this.$eventBus.$emit('fill-fields')
    },

    acceptFMS(item) {
      item.isAccept = true;
      this.accept = true;
      this.$store.commit("UPDATE_FMS", item);
    },
    done(item) {
      this.$http.get("/fms/" + item.id).then((res) => {
        this.$store.commit("UPDATE_FMS", res.data.result);

        this.doneDialog = true;
      });
    },

    getFMS() {
      this.loading = true;
      if (this.status !== this.statues.length) {
        this.options.Status = this.status;
      } else {
        this.options.Status = "";
      }
      let filter =
        "?" +
        Object.entries(this.options)
          .map(([key, val]) => {
            if (val !== "" && val !== null) {
              return `${key}=${val}`;
            }
          })
          .join("&");

      this.$http.get("/FMS" + filter.replace("?&", "?")).then((prod) => {
          this.fms = prod.data.data;
          this.loading = false;
          this.total = prod.data.count;
        })
        .catch((e) => {
          this.$store.commit("UPDATE_SNACKBAR", true);
          this.$store.commit(
            "UPDATE_SNACKBAR_MESSAGE",
            e.response.data.message
          );
        }); 
    },
  },
  
};
</script>
